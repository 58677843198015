import { Homepage } from '../../types/homepage';
import APIServiceImpl from '../api/api.service';
import ServiceResponse from '../api/response.service';
import { HomePageService } from './home-page.interface';

const commonComponents = [
  'Footer',
  'Footer.Background',
  'Footer.BackgroundMobile',
  'Footer.ContactInformation',
  'Footer.ContactInformation.Icon',
  'Footer.Logos',
  'Footer.Logos.Logo',
  'Footer.Menus',
  'Footer.Social',
  'Footer.Social.Link',
  'Footer.Social.Icon',
  'Header',
  'Header.Logo',
  'Header.Menus',
  'Header.Menus.Submenus',
  'Header.Links',
  'Header.Menus.Icon',
  'Quote',
  'Quote.Background',
  'Quote.GetSolarQuoteSection',
  'Quote.GetSolarQuoteSection.Media',
  'Quote.GetSolarQuoteSection.Link',
  'Quote.ScheduleCallSection',
  'Quote.ScheduleCallSection.Link',
];

const componentsData = [
  ...commonComponents,
  'Animations',
  'Animations.Media',
  'Banners',
  'Banners.Background',
  'Banners.Link',
  'DiscountPopup',
  'DiscountPopup.CashbackImage',
  'DiscountPopup.Image',
  'DiscountPopup.Button',
  'InformativeBanner.Description',
  'InformativeBanner.Link',
  'GetSolarGuideSection',
  'GetSolarGuideSection.Background',
  'GetSolarGuideSection.Link',
  'GetSolarGuideSection.Media',
  'LearnMoreSection',
  'LearnMoreSection.Background',
  'LearnMoreSection.Link',
  'LearnMoreSection.Media',
  'Logo',
  'Reviews',
  'Reviews.CustomerReview',
  'seo',
  'seo.metaImage',
  'seo.metaSocial',
  'seo.metaSocial.image',
  'GoSolarNowSection',
  'GoSolarNowSection.Link',
  'GoSolarNowSection.Background',
  'GoSolarNowSection.Reasons',
  'GoSolarNowSection.Reasons.Image',
];

export class HomePageServiceImpl extends APIServiceImpl
  implements HomePageService {
  static readonly GET_HOME_PAGE_API = `api/home-page?populate=${componentsData.join(
    ',',
  )}`;
  static readonly GET_HOME_PAGE_COMMON_DATA_API = `api/home-page?populate=${commonComponents.join(
    ',',
  )}`;

  async getHomePage(): Promise<ServiceResponse<Homepage>> {
    try {
      const url = HomePageServiceImpl.GET_HOME_PAGE_API;
      const { data: res } = await this.get(url);
      return new ServiceResponse<Homepage>(res);
    } catch (error) {
      return new ServiceResponse<Homepage>(undefined, error);
    }
  }

  async getCommonData(): Promise<ServiceResponse<Homepage>> {
    try {
      const url = HomePageServiceImpl.GET_HOME_PAGE_COMMON_DATA_API;
      const { data: res } = await this.get(url);
      return new ServiceResponse<Homepage>(res);
    } catch (error) {
      return new ServiceResponse<Homepage>(undefined, error);
    }
  }
}
